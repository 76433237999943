/* eslint-disable react/function-component-definition */
import { useCallback, useMemo, lazy, Suspense, ComponentType } from 'react';

import { AnimatePresence as AnimatePresenceOriginal } from 'framer-motion';
import { Routes, useLocation, Route, Navigate } from 'react-router-dom';

import { useAuth } from 'hooks';
import type { RouteConfigItem, RouteProps } from 'models';
import { getNormalizedRoutes } from 'utils';
import { AppLayout } from 'layouts';

import { ProtectedPageWrapper, RouteContext } from './components';
import { RouterCreatorProps } from './types';

const AnimatePresence: any = AnimatePresenceOriginal;

export function importarRota(elementPath: string) {
  return () => import(`../pages/${elementPath}` as const);
}

function RenderElement({
  route,
  redirectPath,
  routerAnimationType,
  overflowHiddenMotionWrapper,
}: {
  route: RouteConfigItem;
  redirectPath: RouterCreatorProps['redirectPath'];
  routerAnimationType: RouterCreatorProps['routerAnimationType'];
  overflowHiddenMotionWrapper: RouterCreatorProps['overflowHiddenMotionWrapper'];
}) {
  const importComponent = useCallback((elementPath: string) => {
    return lazy<ComponentType<RouteProps>>(importarRota(elementPath));
  }, []);

  const Element = route?.elementPath
    ? importComponent(route.elementPath)
    : route.element;
  const routeProps: RouteProps = {
    name: route?.name,
    routeTitle: route?.routeTitle,
    path: route.path,
  };

  return (
    <Suspense fallback={<></>}>
      <RouteContext route={route}>
        {Element && (
          <ProtectedPageWrapper
            route={route}
            redirectPath={redirectPath}
            animationType={routerAnimationType}
            overflowHiddenMotionWrapper={overflowHiddenMotionWrapper}
          >
            <Element {...routeProps} />
          </ProtectedPageWrapper>
        )}
      </RouteContext>
    </Suspense>
  );
}

export function RouterCreator({
  redirectPath,
  routesConfig,
  permissionValidator,
  fallbackRedirectPath = '/',
  routerAnimationType = 'leftToRight',
  overflowHiddenMotionWrapper = false,
}: RouterCreatorProps): JSX.Element {
  const location = useLocation();
  const { usuario, loading } = useAuth();

  const memoPrivateRoutes = useMemo(() => {
    if (!usuario) {
      return [];
    }
    const normalizedRoutes = getNormalizedRoutes({
      routes: routesConfig,
      removeNoRenderRoutes: true,
      filter: {
        isPrivate: true,
        permissionValidator,
      },
    });

    return normalizedRoutes;
  }, [usuario, routesConfig, permissionValidator]);

  const memoPublicRoutes = useMemo(() => {
    const normalizedRoutes = getNormalizedRoutes({
      routes: routesConfig,
      removeNoRenderRoutes: true,
      filter: {
        isPrivate: false,
        permissionValidator,
      },
    });

    return normalizedRoutes;
  }, [permissionValidator, routesConfig]);

  if (loading) {
    return <></>;
  }

  return (
    <AnimatePresence initial exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        {memoPublicRoutes.map(route => (
          <Route
            key={route.id}
            path={route.path}
            element={
              <RenderElement
                redirectPath={redirectPath}
                routerAnimationType={routerAnimationType}
                overflowHiddenMotionWrapper={overflowHiddenMotionWrapper}
                route={route}
              />
            }
          />
        ))}
        {!!usuario && (
          <Route element={<AppLayout />}>
            {memoPrivateRoutes.map(route => (
              <Route
                key={route.id}
                path={route.path}
                element={
                  <RenderElement
                    redirectPath={redirectPath}
                    routerAnimationType={routerAnimationType}
                    overflowHiddenMotionWrapper={overflowHiddenMotionWrapper}
                    route={route}
                  />
                }
              />
            ))}
          </Route>
        )}
        {!loading && (
          <Route
            path="*"
            element={<Navigate to={fallbackRedirectPath || redirectPath} />}
          />
        )}
      </Routes>
    </AnimatePresence>
  );
}
