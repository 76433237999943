import { ReactNode, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from 'hooks';
import { RouteConfigItem } from 'models';

import { AnimationType } from '.';
import { MotionWrapper } from './MotionWrapper';

interface ProtectedPageWrapperProps {
  route: RouteConfigItem;
  children: ReactNode;
  redirectPath: string;
  overflowHiddenMotionWrapper?: boolean;
  animationType?: AnimationType;
}

export function ProtectedPageWrapper({
  children,
  route,
  redirectPath,
  overflowHiddenMotionWrapper,
  animationType = 'leftToRight',
}: ProtectedPageWrapperProps): JSX.Element {
  const { usuario, loading } = useAuth();
  const location = useLocation();

  const memoCanAccess = useMemo(() => {
    return !!route?.isPrivate === !!usuario;
  }, [usuario, route]);

  if (loading) {
    return <></>;
  }

  if (!memoCanAccess) {
    return (
      <MotionWrapper
        overflowHidden={overflowHiddenMotionWrapper}
        animationType={animationType}
      >
        <Navigate
          to={route?.redirectPath || redirectPath}
          state={{ from: location }}
          replace
        />
      </MotionWrapper>
    );
  }

  return (
    <MotionWrapper
      overflowHidden={overflowHiddenMotionWrapper}
      animationType={animationType}
    >
      {children}
    </MotionWrapper>
  );
}
