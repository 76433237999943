import { Opcao } from 'components';
import { Feriado, RespostaRequisicao, ForcaTarefa } from 'models';
import { createContext } from 'react';

export interface ConfiguracoesAgendaState {
  id_unidade: number | null;
  qtd_max_audiencia_horario: number;
  tempo_audiencia_min: number;
  expediente_inicio: string;
  expediente_fim: string;
  expediente_segunda: boolean;
  expediente_terca: boolean;
  expediente_quarta: boolean;
  expediente_quinta: boolean;
  expediente_sexta: boolean;
  expediente_sabado: boolean;
  expediente_domingo: boolean;
  unidades: Opcao[] | null;
  feriadosAnuais: Feriado[];
  intervalo_inicio: string;
  intervalo_fim: string;
  intervalo_impressao: boolean;
  possui_intervalo: boolean;
  reagendamento_segunda: boolean;
  reagendamento_terca: boolean;
  reagendamento_quarta: boolean;
  reagendamento_quinta: boolean;
  reagendamento_sexta: boolean;
  reagendamento_sabado: boolean;
  reagendamento_domingo: boolean;
  eh_para_reagendamento: boolean;
  feriadosProgramadosResultado:
    | RespostaRequisicao<Feriado[] | null>
    | undefined;
  qtdMesesFeriadoProgramado: number;
  id: string;
  forcasTarefasResultado: RespostaRequisicao<ForcaTarefa[] | null> | undefined;
  unidade_compartilhada?: Record<string, boolean>;
}

export interface ConfiguracoesAgendaContextData
  extends ConfiguracoesAgendaState {
  mudarUnidade(idUnidade: number): void;
  mudarQtdMaxAudienciaHorario(qtd: number): void;
  mudarTempoAudienciaMin(tempo: number): void;
  mudarExpedienteInicio(horario: string): void;
  mudarExpedienteFim(horario: string): void;
  mudarExpedienteSegunda(flag: boolean): void;
  mudarExpedienteTerca(flag: boolean): void;
  mudarExpedienteQuarta(flag: boolean): void;
  mudarExpedienteQuinta(flag: boolean): void;
  mudarExpedienteSexta(flag: boolean): void;
  mudarExpedienteSabado(flag: boolean): void;
  mudarExpedienteDomingo(flag: boolean): void;
  mudarReagendamentoSegunda(flag: boolean): void;
  mudarReagendamentoTerca(flag: boolean): void;
  mudarReagendamentoQuarta(flag: boolean): void;
  mudarReagendamentoQuinta(flag: boolean): void;
  mudarReagendamentoSexta(flag: boolean): void;
  mudarReagendamentoSabado(flag: boolean): void;
  mudarReagendamentoDomingo(flag: boolean): void;
  mudarEhParaReagendamento(flag: boolean): void;
  mudarFeriadosAnuais(feriados: Feriado[] | [] | null): void;
  mudarFeriadosProgramadosResultado(
    resultado: RespostaRequisicao<Feriado[] | null | undefined>,
  ): void;
  mudarQtdDeMesesFeriadoProgramado(qtdMeses: number): void;
  mudarId(id: string): void;
  mudarUnidades(unidades: Opcao[]): void;
  mudarForcasTarefas(
    resultado: RespostaRequisicao<ForcaTarefa[] | [] | null>,
  ): void;
  mudarIntervaloInicio(horario: string): void;
  mudarIntervaloFim(horario: string): void;
}

export const ConfiguracoesAgendaContext =
  createContext<ConfiguracoesAgendaContextData>(
    {} as ConfiguracoesAgendaContextData,
  );
