import { Opcao } from 'components';
import {
  ConfiguracoesAgendaContextData,
  ConfiguracoesAgendaState,
} from 'contexts';
import { Feriado, ForcaTarefa, RespostaRequisicao } from 'models';
import { useCallback, useMemo, useState } from 'react';

export function useBloc() {
  const [data, setData] = useState<ConfiguracoesAgendaState>({
    id_unidade: 0,
    unidades: [],
    feriadosAnuais: [],
    feriadosProgramadosResultado: { resultado: [] },
    qtdMesesFeriadoProgramado: 0,
    expediente_fim: '',
    expediente_inicio: '',
    expediente_domingo: false,
    expediente_quarta: false,
    expediente_quinta: false,
    expediente_sabado: false,
    expediente_segunda: false,
    expediente_sexta: false,
    expediente_terca: false,
    qtd_max_audiencia_horario: 0,
    tempo_audiencia_min: 0,
    id: '',
    forcasTarefasResultado: { resultado: [] },
    intervalo_inicio: '',
    intervalo_fim: '',
    intervalo_impressao: false,
    possui_intervalo: false,
    reagendamento_segunda: false,
    reagendamento_terca: false,
    reagendamento_quarta: false,
    reagendamento_quinta: false,
    reagendamento_sexta: false,
    reagendamento_sabado: false,
    reagendamento_domingo: false,
    eh_para_reagendamento: false,
  });

  const mudarId = useCallback((id: string) => {
    setData(oldState => ({
      ...oldState,
      id,
    }));
  }, []);

  const mudarUnidade = useCallback((idUnidade: number) => {
    setData(oldState => ({
      ...oldState,
      id_unidade: idUnidade,
    }));
  }, []);

  const mudarQtdMaxAudienciaHorario = useCallback((qtd: number) => {
    setData(oldState => ({
      ...oldState,
      qtd_max_audiencia_horario: qtd,
    }));
  }, []);

  const mudarTempoAudienciaMin = useCallback((tempo: number) => {
    setData(oldState => ({
      ...oldState,
      tempo_audiencia_min: tempo,
    }));
  }, []);

  const mudarExpedienteInicio = useCallback((horario: string) => {
    setData(oldState => ({
      ...oldState,
      expediente_inicio: horario,
    }));
  }, []);

  const mudarExpedienteFim = useCallback((horario: string) => {
    setData(oldState => ({
      ...oldState,
      expediente_fim: horario,
    }));
  }, []);

  const mudarIntervaloInicio = useCallback((horario: string) => {
    setData(oldState => ({
      ...oldState,
      intervalo_inicio: horario,
    }));
  }, []);

  const mudarIntervaloFim = useCallback((horario: string) => {
    setData(oldState => ({
      ...oldState,
      intervalo_fim: horario,
    }));
  }, []);

  const mudarExpedienteSegunda = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      expediente_segunda: flag,
    }));
  }, []);

  const mudarExpedienteTerca = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      expediente_terca: flag,
    }));
  }, []);

  const mudarExpedienteQuarta = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      expediente_quarta: flag,
    }));
  }, []);

  const mudarExpedienteQuinta = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      expediente_quinta: flag,
    }));
  }, []);

  const mudarExpedienteSexta = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      expediente_sexta: flag,
    }));
  }, []);

  const mudarExpedienteSabado = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      expediente_sabado: flag,
    }));
  }, []);

  const mudarExpedienteDomingo = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      expediente_domingo: flag,
    }));
  }, []);

  const mudarReagendamentoSegunda = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      reagendamento_segunda: flag,
    }));
  }, []);

  const mudarReagendamentoTerca = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      reagendamento_terca: flag,
    }));
  }, []);

  const mudarReagendamentoQuarta = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      reagendamento_quarta: flag,
    }));
  }, []);

  const mudarReagendamentoQuinta = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      reagendamento_quinta: flag,
    }));
  }, []);

  const mudarReagendamentoSexta = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      reagendamento_sexta: flag,
    }));
  }, []);

  const mudarReagendamentoSabado = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      reagendamento_sabado: flag,
    }));
  }, []);

  const mudarReagendamentoDomingo = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      reagendamento_domingo: flag,
    }));
  }, []);

  const mudarEhParaReagendamento = useCallback((flag: boolean) => {
    setData(oldState => ({
      ...oldState,
      eh_para_reagendamento: flag,
    }));
  }, []);

  const mudarFeriadosAnuais = useCallback((feriados: Feriado[]) => {
    setData(oldState => ({
      ...oldState,
      feriadosAnuais: feriados,
    }));
  }, []);

  const mudarQtdDeMesesFeriadoProgramado = useCallback((qtdMeses: number) => {
    setData(oldState => ({
      ...oldState,
      qtdMesesFeriadoProgramado: qtdMeses,
    }));
  }, []);

  const mudarFeriadosProgramadosResultado = useCallback(
    (resultado: RespostaRequisicao<Feriado[] | null>) => {
      setData(oldState => ({
        ...oldState,
        feriadosProgramadosResultado: resultado,
      }));
    },
    [],
  );

  const mudarUnidades = useCallback((unidades: Opcao[]) => {
    setData(oldState => ({
      ...oldState,
      unidades,
    }));
  }, []);

  const mudarForcasTarefas = useCallback(
    (resultado: RespostaRequisicao<ForcaTarefa[] | [] | null>) => {
      setData(oldState => ({
        ...oldState,
        forcasTarefasResultado: resultado,
      }));
    },
    [],
  );

  const memoValues: ConfiguracoesAgendaContextData = useMemo(
    () => ({
      id_unidade: data.id_unidade,
      unidades: data.unidades,
      mudarUnidade,
      mudarUnidades,
      feriadosAnuais: data.feriadosAnuais,
      mudarFeriadosAnuais,
      feriadosProgramadosResultado: data.feriadosProgramadosResultado,
      mudarFeriadosProgramadosResultado,
      qtdMesesFeriadoProgramado: data.qtdMesesFeriadoProgramado,
      mudarQtdDeMesesFeriadoProgramado,
      expediente_fim: data.expediente_fim,
      expediente_inicio: data.expediente_inicio,
      expediente_domingo: data.expediente_domingo,
      expediente_quarta: data.expediente_quarta,
      expediente_quinta: data.expediente_quinta,
      expediente_sabado: data.expediente_sabado,
      expediente_segunda: data.expediente_segunda,
      expediente_sexta: data.expediente_sexta,
      expediente_terca: data.expediente_terca,
      qtd_max_audiencia_horario: data.qtd_max_audiencia_horario,
      tempo_audiencia_min: data.tempo_audiencia_min,
      id: data.id,
      forcasTarefasResultado: data.forcasTarefasResultado,
      intervalo_inicio: data.intervalo_inicio,
      intervalo_fim: data.intervalo_fim,
      intervalo_impressao: data.intervalo_impressao,
      possui_intervalo: data.possui_intervalo,
      reagendamento_segunda: data.reagendamento_segunda,
      reagendamento_terca: data.reagendamento_terca,
      reagendamento_quarta: data.reagendamento_quarta,
      reagendamento_quinta: data.reagendamento_quinta,
      reagendamento_sexta: data.reagendamento_sexta,
      reagendamento_sabado: data.reagendamento_sabado,
      reagendamento_domingo: data.reagendamento_domingo,
      eh_para_reagendamento: data.eh_para_reagendamento,
      mudarExpedienteDomingo,
      mudarExpedienteFim,
      mudarExpedienteInicio,
      mudarExpedienteQuarta,
      mudarExpedienteQuinta,
      mudarExpedienteSabado,
      mudarExpedienteSegunda,
      mudarExpedienteSexta,
      mudarExpedienteTerca,
      mudarQtdMaxAudienciaHorario,
      mudarTempoAudienciaMin,
      mudarId,
      mudarForcasTarefas,
      mudarIntervaloInicio,
      mudarIntervaloFim,
      mudarReagendamentoSegunda,
      mudarReagendamentoTerca,
      mudarReagendamentoQuarta,
      mudarReagendamentoQuinta,
      mudarReagendamentoSexta,
      mudarReagendamentoDomingo,
      mudarReagendamentoSabado,
      mudarEhParaReagendamento,
    }),
    [
      mudarUnidade,
      mudarUnidades,
      mudarFeriadosAnuais,
      mudarFeriadosProgramadosResultado,
      mudarQtdDeMesesFeriadoProgramado,
      mudarExpedienteDomingo,
      mudarExpedienteFim,
      mudarExpedienteInicio,
      mudarExpedienteQuarta,
      mudarExpedienteQuinta,
      mudarExpedienteSabado,
      mudarExpedienteSegunda,
      mudarExpedienteSexta,
      mudarExpedienteTerca,
      mudarQtdMaxAudienciaHorario,
      mudarTempoAudienciaMin,
      mudarId,
      mudarForcasTarefas,
      mudarIntervaloInicio,
      mudarIntervaloFim,
      mudarReagendamentoSegunda,
      mudarReagendamentoTerca,
      mudarReagendamentoQuarta,
      mudarReagendamentoQuinta,
      mudarReagendamentoSexta,
      mudarReagendamentoDomingo,
      mudarReagendamentoSabado,
      mudarEhParaReagendamento,
      data.id_unidade,
      data.unidades,
      data.feriadosAnuais,
      data.feriadosProgramadosResultado,
      data.qtdMesesFeriadoProgramado,
      data.expediente_fim,
      data.expediente_inicio,
      data.expediente_domingo,
      data.expediente_quarta,
      data.expediente_quinta,
      data.expediente_sabado,
      data.expediente_segunda,
      data.expediente_sexta,
      data.expediente_terca,
      data.qtd_max_audiencia_horario,
      data.tempo_audiencia_min,
      data.id,
      data.forcasTarefasResultado,
      data.intervalo_inicio,
      data.intervalo_fim,
      data.intervalo_impressao,
      data.possui_intervalo,
      data.reagendamento_segunda,
      data.reagendamento_terca,
      data.reagendamento_quarta,
      data.reagendamento_quinta,
      data.reagendamento_sexta,
      data.reagendamento_sabado,
      data.reagendamento_domingo,
      data.eh_para_reagendamento,
    ],
  );

  return {
    memoValues,
  };
}
