import createAuthZeroClient, { Auth0Client } from '@auth0/auth0-spa-js';

import { authZeroConfig } from 'config';

let authZeroClient: Auth0Client | null = null;

export const authZero = async (): Promise<Auth0Client> => {
  if (!authZeroClient) {
    authZeroClient = await createAuthZeroClient(authZeroConfig);
  }
  const isAuthenticated = await authZeroClient.isAuthenticated();

  if (isAuthenticated) {
    await authZeroClient.getTokenSilently();
  }

  (window as any).authZero = authZeroClient;

  return authZeroClient;
};

// let AuthZeroClient: Auth0Client | null = null;

// if (!navigator.userAgent.includes('jsdom')) {
//   AuthZeroClient = new Auth0Client(authZeroConfig);
// }

// export { AuthZeroClient };
