import { AxiosError } from 'axios';
import { tratarRespostaExcecao } from 'utils/tratar-resposta-excecao';
import { useGerenciadorDeMensagens } from './use-gerenciador-de-mensagens';

export function useCriarMensagemExcecao(): (error: AxiosError) => void {
  const criarMensagem = useGerenciadorDeMensagens();
  function criarMensagemExcecao(error: AxiosError): void {
    const err = error as AxiosError;
    const respostaExcecao = tratarRespostaExcecao(err);

    criarMensagem({
      titulo: respostaExcecao.titulo || 'Erro',
      texto: respostaExcecao.mensagem || 'Entre em contato com o nosso suporte',
      tipo: respostaExcecao.tipo || 'erro',
    });
  }

  return criarMensagemExcecao;
}
