import { AxiosError } from 'axios';
import { RespostaExcecao } from '../models/resposta-excecao';

export function tratarRespostaExcecao(error: AxiosError): RespostaExcecao {
  let respostaExcecao: RespostaExcecao = {
    status: 0,
    tipo: 'erro',
    tipoExcecao: '',
    mensagem: '',
    detalhe: '',
    instancia: '',
  };

  if (error.response) {
    if (
      error.response.data instanceof ArrayBuffer &&
      error.response.data.byteLength
    ) {
      const decoder = new TextDecoder('utf-8');
      const data = decoder.decode(new Uint8Array(error.response.data));
      try {
        const jsonResponse = JSON.parse(data);
        respostaExcecao = {
          titulo: jsonResponse.titulo,
          status: jsonResponse.status,
          tipo: jsonResponse.tipo,
          tipoExcecao: jsonResponse.tipoExcecao,
          mensagem: jsonResponse.mensagem,
          detalhe: jsonResponse.detalhe,
          instancia: jsonResponse.instancia,
        };
      } catch (e) {
        return {
          status: 0,
          tipo: 'erro',
          tipoExcecao: '',
          mensagem: '',
          detalhe: '',
          instancia: '',
        } as RespostaExcecao;
      }
    } else {
      const err = error as AxiosError;
      respostaExcecao = { ...err?.response?.data };
    }
  } else {
    respostaExcecao = {
      status: 0,
      tipo: 'erro',
      tipoExcecao: '',
      mensagem: '',
      detalhe: '',
      instancia: '',
    } as RespostaExcecao;
  }

  return respostaExcecao;
}
